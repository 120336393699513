<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-auto col-lg-8 col-xl-6">
      <card title="Add new site" :showFilter="false">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Site name" v-model="site.SiteName" required></fg-input>
            </div>
          </div>
          <div class="row float-right mt-5">
            <div class="col mt-4 mb-3">
              <p-button
                type="danger"
                round
                @click="cancel"
                class="mr-3"
                fa="fal fa-times-octagon"
              >Cancel</p-button>
              <p-button type="info" fa="fal fa-save" round nativeType="submit">Save</p-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import odata from "@/services/odata.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { db, getCustomer } from "@/db";
import utils from "@/utils";
import store from "@/router/store";

export default {
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      site: {
        CustomerId: "",
        CustomerName: "",
        Id: "",
        SiteName: "",
        Archived: false
      }
    };
  },
  mounted() {
    this.site.Id = utils.newId();
    this.site.CustomerId = store.getters.selectedCustomer.Id;
    this.site.CustomerName = store.getters.selectedCustomer.CustomerName;
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    save() {
      this.isLoading = true;
      getCustomer()
        .collection("sites")
        .doc(this.site.Id)
        .set(this.site, { merge: true })
        .then(() => {
          this.$router.go(-1);
          this.$helpers.dispatch("showSuccess", {
            vm: this,
            msg: "Site created successfully"
          });
        })
        .catch(err => {
          this.isLoading = false;
          this.$helpers.dispatch("showError2", {
            vm: this,
            err: err
          });
        });
    }
  }
};
</script>
